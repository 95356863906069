@import './fonts.css';
@tailwind base;
@import 'react-datepicker/dist/react-datepicker';

@tailwind components;

@tailwind utilities;
@layer utilities {
  @layer responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

html {
  @apply !px-0;
}
html {
  font-family: 'CasperVN';
}
body {
  margin-top: 0 !important;
}
input,
textarea,
select,
input::placeholder,
textarea::placeholder,
select::placeholder {
  font-size: 16px;
}
input,
textarea,
select {
  color: #010103;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #9c9c9c;
}
a {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
* {
  -webkit-tap-highlight-color: transparent;
}
.rc-menu-submenu-active,
.rc-menu-submenu-title {
  padding: 0 !important;
  background-color: unset !important;
}
.rc-menu-submenu-arrow {
  display: none !important;
}

.rc-menu-vertical {
  padding: 0 !important;
}
.rc-menu-item-active {
  background: unset !important;
}

.top-213 {
  top: 213px !important;
}
.top-155 {
  top: 155px !important;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
:root {
  --primary-color: #ff424e;
  --text-dark: #303538;
  --font-base: 16px;
  --toastify-font-family: var(--font-mulish) !important;
  --toastify-toast-min-height: 36px !important;
  --toastify-color-error: #ff424e !important;
  --toastify-toast-width: 380px;
}
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}
/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}
html {
  font-family: 'CasperVN';
  padding-right: 0 !important;
}
@media (min-width: 441px) {
  html {
    background: #c9c9c9;
    padding-right: 0 !important;
  }
}
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  touch-action: manipulation;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  display: none;
}

@media (max-width: 1024px) {
  .hiddenScrollBar::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .scrollBarXCS {
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  html::-webkit-scrollbar {
    display: none;
  }
  .scrollBarXCS::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); */
    border-radius: 100px;
    background-color: #ececec;
  }

  .scrollBarXCS::-webkit-scrollbar {
    width: 3px;
    height: 6px;
    border-radius: 100px;
    display: block;
    background-color: #ececec;
  }

  .scrollBarXCS::-webkit-scrollbar-thumb {
    background-color: #888;
    height: 4px;
    width: 30px;
    border-radius: 100px;
  }
}
.scrollBar::-webkit-scrollbar {
  display: block;
}
.root_app {
  max-width: 440px;
  margin: 0 auto;
  background-color: #e8e8e8;
  min-height: 100vh;
}
/* Mega menu */

.ui-selected.activeMegaMenu {
  @apply border-l-4 border-primary-base bg-primary-base;
}
/* CSS slick */
.login .slick-dots li {
  margin: 0 !important;
}
.login .slick-dots {
  @apply sm:!bottom-3 md:!bottom-0;
}
.login .slick-dots li button,
.login .slick-dots li button:before {
  text-align: center;
  width: 10px !important;
  height: 10px !important;
  padding: 0 !important;
  margin: 0 auto !important;
  line-height: 1 !important;
  font-size: 10px !important;
}
.slick-slide > div {
  font-size: 0;
}
.slick-active,
.slick-current {
  @apply z-10;
}
.slickHome .slick-dots {
  @apply bottom-0 z-50 !flex justify-center gap-2 relative;
}
.slickHome .slick-dots li {
  @apply m-0 h-2 w-2 p-0;
}
.slickHome .slick-dots li button {
  @apply m-0 h-full w-full p-0 before:content-[''];
}
.slickHome .slick-dots li button:before {
  @apply relative block h-full w-full rounded-[10px]  bg-black-400;
}
.slickCate .slick-dots {
  @apply !bottom-[-10px];
}
.image-summary {
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
}
/* div.productCard:nth-child(4n + 1):not(.relatedProductSlider) .itemProducts,
div[class*='swiper-wrapper']
  div[class*='swiper-slide']:nth-child(4n + 1)
  > div:not(.relatedProductSlider)
  .itemProducts,
div[class*='slick-track']
  div[class*='slick-slide']:nth-child(4n + 1)
  > div
  > div:not(.relatedProductSlider)
  .itemProducts {
  background-image: url('/assets/images/bg-vang.png');
  background-color: #ffeaaf;
}

div.productCard:nth-child(4n + 2):not(.relatedProductSlider) .itemProducts,
div[class*='swiper-wrapper']
  div[class*='swiper-slide']:nth-child(4n + 2)
  > div:not(.relatedProductSlider)
  .itemProducts,
div[class*='slick-track']
  div[class*='slick-slide']:nth-child(4n + 2)
  > div
  > div:not(.relatedProductSlider)
  .itemProducts {
  background-image: url('/assets/images/bg-xanh.png');
  background-color: #d7dff7;
}

div.productCard:nth-child(4n + 3):not(.relatedProductSlider) .itemProducts,
div[class*='swiper-wrapper']
  div[class*='swiper-slide']:nth-child(4n + 3)
  > div:not(.relatedProductSlider)
  .itemProducts,
div[class*='slick-track']
  div[class*='slick-slide']:nth-child(4n + 3)
  > div
  > div:not(.relatedProductSlider)
  .itemProducts {
  background-image: url('/assets/images/bg-hong.png');
  background-color: #fecce4;
}

div.productCard:nth-child(4n + 4):not(.relatedProductSlider) .itemProducts,
div[class*='swiper-wrapper']
  div[class*='swiper-slide']:nth-child(4n + 4)
  > div:not(.relatedProductSlider)
  .itemProducts,
div[class*='slick-track']
  div[class*='slick-slide']:nth-child(4n + 4)
  > div
  > div:not(.relatedProductSlider)
  .itemProducts {
  background-image: url('/assets/images/bg-tim.png');
  background-color: #e6d0f9;
}
.relatedProductSlider .itemProducts .image-thumb {
  background-color: #eaf2f9;
} */
.productCardSlider {
  @apply px-[6px];
}
.yarl__button {
  filter: none !important;
  display: none !important;
}
.yarl__toolbar {
  right: auto !important;
  left: 0 !important;
}
.yarl__toolbar .yarl__button:last-child {
  display: block !important;
}
.yarl__thumbnails_track button {
  background-color: rgb(0 0 0 / 15%) !important;
  border: none !important;
}
.yarl__thumbnails_track button.yarl__thumbnails_thumbnail_active {
  background-color: #000000 !important;
}
.yarl__thumbnails_track button img {
  display: none;
}
.flashSaleSwp {
  @apply !px-2;
}
.productCarousel {
  @apply pl-2;
}
.relatedProductSlider {
  @apply pl-2;
}
.scrollBarCS {
  overflow-x: hidden;
}
.flash-sale--content .slick-slide {
  max-width: 190px;
  width: 190px;
}
.pdc--content .slick-slide {
  max-width: 190px;
  width: 190px;
}
.related-product .slick-slide {
  max-width: 190px;
  width: 190px;
}
.flash-sale--content .slick-track {
  display: flex;
}
.pdc--content .slick-track {
  display: flex;
}
button {
  @apply outline-none focus:outline-none;
}
.scrollBarCS::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); */
  border-radius: 100px;
  background-color: #ececec;
}
input,
textarea {
  outline: none;
}
.scrollBarCS::-webkit-scrollbar {
  width: 3px;
  border-radius: 100px;
  display: block;
  background-color: #ececec;
}

.scrollBarCS::-webkit-scrollbar-thumb {
  background-color: #00c1d5;
  height: 50px;
  border-radius: 100px;
}
.itemProducts {
  background-position: top left;
  background-size: 100%;
  background-repeat: no-repeat;
}
/* End CSS slick */
.DropdownMenuContent {
  min-height: 200px;
  min-width: 233px;
  left: 0;
  position: relative;
  padding: 18px 12px;
  list-style-type: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);
  transform-origin: var(--radix-dropdown-menu-content-transform-origin);
  animation: scaleIn 0.1s ease-out;
}
.DropdownLanguageContent {
  min-height: 40px;
  min-width: 176px;
  position: relative;
  padding: 0;
  list-style-type: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 5px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  animation: scaleIn 0.1s ease-out;
}
.uploadBox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
/* Custom toastify */
.Toastify__toast {
  @apply text-sm !rounded-full relative !p-1;
}
.Toastify__toast-container {
  @apply sm:!max-w-[360px] md:!max-w-[380px] !min-w-[320px] !w-max !mx-auto !left-[50%] !translate-x-[-50%];
}
.Toastify__toast-icon {
  @apply !h-7 !w-7;
}
.Toastify__close-button {
  @apply absolute right-3 top-[50%] translate-y-[-50%];
}
.Toastify__toast-container--top-center {
  top: 1em !important;
  left: 50% !important;
  margin-top: 10px;
  transform: translateX(-50%) !important;
}
.Toastify__toast-body {
  @apply !p-1 !pr-8 text-center sm:text-xs md:text-[13px];
}
.Toastify__toast-theme--dark {
  @apply !bg-black-400/80;
}
.Toastify__toast-container--bottom-left,
.Toastify__toast-container--bottom-center,
.Toastify__toast-container--bottom-right {
  bottom: 90px !important;
}
.Toastify__toast-container--top-left,
.Toastify__toast-container--top-center,
.Toastify__toast-container--top-right {
  top: 32px !important;
}
.link-section::after {
  content: unset;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: #00c1d5;
  position: absolute;
  left: 0;
  bottom: -4px;
}

.link-section.active {
  @apply font-bold text-primary-base after:content-[''];
}
.policyDraw {
  @apply pt-2;
}
.policyDraw h1 {
  @apply text-base font-bold text-dark-900 mb-5;
}
.policyDraw h2 {
  @apply text-sm font-bold text-dark-900;
}
.policyDraw p {
  @apply text-sm font-normal text-dark-500;
}
.form-check-default .form-checkbox-input {
  position: absolute;
  left: -9999px;
}
.form-check-default .form-checkbox-input:focus {
  box-shadow: none;
}
.form-check-default .form-checkbox-input:not(:checked) + label:after {
  color: #000;
  opacity: 0;
}
.form-check-default .form-checkbox-input:checked + label {
  color: #000;
}
.form-check-default .form-checkbox-input:checked + label:after {
  opacity: 1;
}
.form-check-default .form-checkbox-input:disabled + label {
  color: #777;
}
.form-check-default .form-checkbox-input:disabled + label:before {
  border: 1px solid #777;
  background-color: #eee;
}
.form-check-default .form-checkbox-input:disabled:checked + label {
  color: #777;
}
.form-check-default .form-checkbox-input:disabled:checked + label:before {
  border-color: #707070;
  background-color: #eee;
  background-image: url('/check-gray.svg');
}
.form-check-default .form-checkbox-label {
  position: relative;
  padding-left: 28px;
  display: flex;
  user-select: none;
}
.form-check-default .form-checkbox-label-sm {
  position: relative;
  padding-left: 28px;
  display: flex;
  user-select: none;
}
.form-check-default .form-checkbox-input:not(:checked) + label:after {
  color: #373737;
  opacity: 0;
}
.form-check-default .form-checkbox-label:before {
  content: '';
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #707070;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top: 0px;
}

.form-check-default .form-checkbox-label-sm:before {
  content: '';
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #707070;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top: 2px;
}
.form-check-default .form-checkbox-input:checked + label:before {
  border-color: #00c1d5;
  background-color: #00c1d5;
  background-image: url('/check.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 10px;
}
.form-check-default .form-checkbox-input:disable:checked + label:before {
  border-color: #707070;
  background-color: #eee;
  background-image: url('/check-gray.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 10px;
}

.main-description img {
  @apply !mb-2 !mt-1 !h-auto !w-full;
}
.main-description table {
  @apply !mx-0 !my-1 !h-auto !w-full !block !overflow-x-auto;
}
.main-description ol {
  @apply !ml-3;
}
.main-description p {
  @apply !mb-2;
}
.main-description li {
  @apply !mb-1;
}
.form-check-default .form-radio-input {
  position: absolute;
  left: -9999px;
}
.form-check-default .form-radio-input:focus {
  box-shadow: none;
}
.form-check-default .form-radio-input:not(:checked) + label:after {
  color: #000;
  opacity: 0;
}
.form-check-default .form-radio-input:checked + label {
  color: #000;
}
.form-check-default .form-radio-input:checked + label:after {
  opacity: 1;
}
.form-check-default .form-radio-input:disabled + label {
  color: #777;
}
.form-check-default .form-radio-input:disabled + label:before {
  border: 1px solid #000;
}
.form-check-default .form-radio-input:disabled:checked + label {
  color: #777;
}
.form-check-default .form-radio-input:disabled:checked + label:before {
  border: 1px solid #000;

  color: #777;
}

.form-check-default .form-radio-label {
  position: relative;
  padding-left: 26px;
  display: flex;
  user-select: none;
}
.form-check-default .form-radio-input:not(:checked) + label:after {
  color: #373737;
  opacity: 0;
}
.form-check-default .form-radio-label:before {
  content: '';
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  top: 4px;
}
.form-check-shipping .form-radio-label:before {
  content: '';
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #777;
  border-radius: 4px;
  background: #fff;
  position: absolute;
  top: 2px;
}
.form-check-default .form-radio-input:checked + label:before {
  border: 1px solid #00c1d5;
  background-color: #00c1d5;
  background-image: url('/check.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 65% 65%;
}
.form-check-shipping
  .form-radio-input:disabled:checked
  + .form-radio-label:before {
  border: 1px solid #777;
  background-color: #eee;
  background-image: url('/check-gray.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 70% 70%;
}
.form-check-default .form-radio-label.middle:before {
  content: '';
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #707070;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.form-check-default.middle .form-checkbox-label:before {
  top: 50%;
  transform: translateY(-50%);
}
.form-check-default.small .form-checkbox-label:before {
  width: 16px;
  height: 16px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

.voucherRight,
.voucherLeft {
  position: relative;
  display: flex;
  height: 100%;
  border: 1px solid rgba(255, 0, 0, 0.1);
  align-items: center;
  /* overflow: hidden; */
}
.voucherRight {
  border-left: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.voucherLeft {
  border-right: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.voucherRight::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 70%;
  border-left: 1px dashed #e4e4e4;
  display: block;
}
.voucherLeft::before {
  content: '';
  position: absolute;
  right: -6px;
  top: -7px;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid rgba(255, 0, 0, 0.1);
  display: block;
  z-index: 1;
  border-radius: 100%;
}
.voucherLeft::after {
  content: '';
  position: absolute;
  right: -6px;
  bottom: -7px;
  top: auto;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid rgba(255, 0, 0, 0.1);
  display: block;
  z-index: 1;
  border-radius: 100%;
}
.yarl__thumbnails_thumbnail {
  background: white !important;
}

.postDetail ul,
.postDetail ol {
  list-style: revert;
  padding-left: 20px;
}
.postDetail p {
  margin-bottom: 8px;
}
.postDetail tr p,
.postDetail td p {
  margin-bottom: 0px !important;
  width: auto !important;
}
.postDetail tr span,
.postDetail td span {
  margin-bottom: 0px !important;
  @apply whitespace-normal;
}
.postDetail ul li,
.postDetail ol li {
  margin-bottom: 5px;
}
.postDetail tr,
.postDetail td {
  border-width: revert !important;
  border-color: revert !important;
  padding: 4px;
  width: auto !important;
  white-space: normal;
}

.main-description a,
.postDetail a {
  @apply text-primary-base;
}

.form-check-filter .form-checkbox-input {
  position: absolute;
  left: -9999px;
}
.form-check-filter .form-checkbox-input:focus {
  box-shadow: none;
}
.form-check-filter .form-checkbox-input:not(:checked) + label:after {
  color: #000;
  opacity: 0;
}
.form-check-filter .form-checkbox-input:checked + label {
  color: #000;
}
.form-check-filter .form-checkbox-input:checked + label:after {
  opacity: 1;
}
.form-check-filter .form-checkbox-input:disabled + label {
  color: #777;
}
.form-check-filter .form-checkbox-input:disabled + label:before {
  border: 1px solid #707070;
}
.form-check-filter .form-checkbox-input:disabled:checked + label {
  color: #777;
}
.form-check-filter .form-checkbox-input:disabled:checked + label:before {
  border-color: #707070;
  background-color: #eee;
  background-image: url('/check-gray.svg');
}
.form-check-filter .form-checkbox-label {
  position: relative;
  padding-left: 22px;
  display: flex;
  user-select: none;
}
.form-check-filter .form-checkbox-label-sm {
  position: relative;
  padding-left: 26px;
  display: flex;
  user-select: none;
}
.form-check-filter .form-checkbox-input:not(:checked) + label:after {
  color: #373737;
  opacity: 0;
}
.form-check-filter .form-checkbox-label:before {
  content: '';
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #707070;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top: 3px;
}

.form-check-filter .form-checkbox-label-sm:before {
  content: '';
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #707070;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top: 2px;
}
.form-check-filter .form-checkbox-input:checked + label:before {
  border-color: #00c1d5;
  background-color: #00c1d5;
  background-image: url('/check.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 9px;
}
.form-check-filter .form-checkbox-input:disable:checked + label:before {
  border-color: #707070;
  background-color: #eee;
  background-image: url('/check-gray.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 10px;
}

.form-check-filter .form-radio-input {
  position: absolute;
  left: -9999px;
}
.form-check-filter .form-radio-input:focus {
  box-shadow: none;
}
.form-check-filter .form-radio-input:not(:checked) + label:after {
  color: #000;
  opacity: 0;
}
.form-check-filter .form-radio-input:checked + label {
  color: #000;
}
.form-check-filter .form-radio-input label {
  position: relative;
}
.form-check-filter .form-radio-input:checked + label:after {
  opacity: 1;
}
.form-check-filter .form-radio-input:disabled + label {
  color: #777;
}
.form-check-filter .form-radio-input:disabled + label:before {
  border: 1px solid #000;
}
.form-check-filter .form-radio-input:disabled:checked + label {
  color: #777;
}
.form-check-filter .form-radio-input:disabled:checked + label:before {
  border: 1px solid #000;
  background: transparent;
  color: #777;
}
.form-check-filter .form-radio-label {
  position: relative;
  padding-left: 30px;
  display: flex;
  user-select: none;
}
.form-check-filter .form-radio-input:not(:checked) + label:after {
  color: #373737;
  opacity: 0;
}
.form-check-filter .form-radio-label:before {
  content: '';
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  top: 0px;
}
.form-check-filter .form-radio-input:checked + label:after {
  content: '';
  left: 0;
  width: 12px;
  height: 12px;
  background-color: #00c1d5;
  border-radius: 100%;
  position: absolute;
  top: 4px;
  left: 4px;
}
.form-check-filter .form-radio-input:checked + label::before {
  border: 1px solid #00c1d5;
  border-radius: 100%;
}
.optionBuy input {
  position: absolute;
  left: -9999px;
}
.optionBuy input:focus {
  box-shadow: none;
}
.preOrderRadio .flag {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  overflow: hidden;
  border-radius: 8px;
}
.preOrderRadio input:checked + label .flag {
  background-position: 102% -2px;
  background-repeat: no-repeat;
  background-size: 38px 39px;
  display: block;
}
.preOrderRadio input:checked + label .flag:before {
  content: '';
  background-position: top right;
  background-repeat: no-repeat;
  width: 12px;
  background-size: 100%;
  display: block;
  height: 12px;
  position: absolute;
  right: 3px;
  top: 5px;
}
.optionBuy input:checked + label .flag {
  background-image: url('/assets/icons/preorderLabel.svg');
}
.optionBuy input:checked + label .flag:before {
  background-image: url('/assets/icons/checkPre.svg');
}
.optionBuyFlashSale input:checked + label .flag {
  background-image: url('/assets/icons/preorderLabelFls.svg');
}
.optionBuyFlashSale input:checked + label .flag:before {
  background-image: url('/assets/icons/checkFls.svg');
}
.optionBuy label:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #ff424e;
  position: absolute;
  display: block;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
}
.optionBuy input:not(:checked) + label:after {
  color: #000;
  opacity: 0;
}
.optionBuy input:checked + label {
  @apply relative bg-white-400 shadow outline-2 outline-primary-base;
}

.optionBuy input:checked + label .sale-price p {
  @apply text-primary-second;
}
.optionBuy input:disabled + label:after {
  content: '';
}
.optionBuy input:disabled + label:before {
  border: 1px solid #000;
}
.optionBuy input:disabled:checked + label {
  color: #777;
}
.optionBuy label {
  @apply rounded-lg outline outline-1 outline-zinc-500;
}

.optionBuyFlashSale input {
  position: absolute;
  left: -9999px;
}
.optionBuyFlashSale input:focus {
  box-shadow: none;
}
.optionBuyFlashSale input + label .discount {
  @apply bg-white;
}
.optionBuyFlashSale input + label .discount span {
  @apply text-dark-400;
}
.optionBuyFlashSale input:checked + label .textTop,
.optionBuyFlashSale input:checked + label .originPrice p {
  @apply !text-dark-400;
}
.optionBuyFlashSale label:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
  position: absolute;
  display: block;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.optionBuyFlashSale input:not(:checked) + label:after {
  color: #000;
  opacity: 0;
}
.optionBuyFlashSale input:checked + label {
  @apply relative bg-white-400 shadow outline-none;
}
.optionBuyFlashSale input:checked + label .sale-price p {
  @apply text-primary-second;
}
.optionBuyFlashSale input:checked + label .discount {
  @apply bg-primary-second;
}
.optionBuyFlashSale input:checked + label .discount span {
  @apply text-stone-50;
}
.optionBuyFlashSale input:disabled + label:after {
  content: '';
}
.optionBuyFlashSale input:disabled + label:before {
  border: 1px solid #000;
}
.optionBuyFlashSale input:disabled:checked + label {
  color: #777;
}
.optionBuyFlashSale label {
  @apply rounded-lg outline outline-1 outline-white/50;
}
.radioReviewOrder input {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}
.radioReviewOrder .reviewOrderInput:focus {
  box-shadow: none;
}
.radioReviewOrder .reviewOrderInput:checked + label {
  @apply border-primary-base font-bold;
}
.radioReviewOrder .reviewOrderInput label {
  position: relative;
}

/* img.imgOtm {
  opacity: 0;
  transition: opacity 0.2s ease;
}

img.loaded {
  opacity: 1;
} */
.grid-fs {
  grid-template-columns: repeat(3, minmax(45%, 45%));
}

/* .swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  height: calc((100% - 30px) / 2) !important;
  width: 40% !important;
  min-height: 200px;
} */

.yarl__button {
  filter: unset !important;
}
.yarl__slide {
  padding: 0 !important;
}

.yarl__thumbnails_thumbnail {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.yarl__thumbnails_thumbnail_active {
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.yarl__thumbnails_thumbnail img {
  display: none;
}
